import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const NFT_DESCRIPTION = checkMobileScreen()
	? '登録する作品をアップロードしてください。\n最大50ファイルまでアップロード可能です。\n対応ファイル形式 : jpg, png, gif, svg'
	: '登録する作品をアップロードしてください。最大50ファイルまでアップロード可能です。\n対応ファイル形式 : jpg, png, gif, svg';

const NFT_INFO = {
	contract_address: '0x7E4932B2D94765798fbA387c0eaAA8bfFc60461D',
	blockchain: 'polygon',
	receiver_wallet_address: '0x6e99a38cf1b6d2f276d5c08fd702652db6986479',
	metadata: [
		{
			title: '',
			description: 'Description of NFT',
			file_fields: [
				{
					key: 'image',
					url: '',
				},
			],
		},
	],
};

const UPLOAD_STATUS = {
	WAITTING: 'waitting',
	COMPLETED: 'completed',
	FAILED: 'failed',
};

const LIMIT_ERROR_CONTENTS =
	'納品できるファイル数を超えています。\n既にアップロード済みファイルを削除してください';
const MAX_ITEMS = 50;
const NFT_ERROR_CONTENTS = 'NFTの登録に失敗しました。';
const MAX_FILE_SIZE_ERROR_CONTENTS = 'ファイルサイズが大きすぎます。\n最大5MBです。';
const MAX_PROGRESS_VALUE = 100;
const MIN_PROGRESS_VALUE = 0;

export default {
	NFT_DESCRIPTION,
	NFT_INFO,
	LIMIT_ERROR_CONTENTS,
	MAX_ITEMS,
	NFT_ERROR_CONTENTS,
	MAX_FILE_SIZE_ERROR_CONTENTS,
	MAX_PROGRESS_VALUE,
	MIN_PROGRESS_VALUE,
	UPLOAD_STATUS,
};
