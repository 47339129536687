import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import TextArea from '@/components/TextArea/TextArea.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import StatusBadge from '@/components/Badge/StatusBadge.vue';
import ProjectService from '@/services/API/project.service';
import TransferService from '@/services/API/transfer.service';
import NFTGardenService from '@/services/API/nft_garden.service.js';
import NFTService from '@/services/API/nft.service.js';

import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import CommonConst from '@/constants/CommonConst';
import NftUploadConst from '@/constants/NftUploadConst';

import moment from 'moment';
import { mapState } from 'vuex';
const NFT_SCREEN_ID = 4;
export default {
	name: 'NFTScreen',
	mixins: [updateProjectStatusMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		ImageUpload,
		TextArea,
		SaveButtonArea,
		BottomButton,
		NotificationModal,
		StatusBadge,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenName: 'デジタル認証',
			/**
			 * @value {String} created_at
			 * @value {String} file_name
			 * @value {String} opensea_url
			 * @value {String} status
			 */
			uploadedContents: [],

			// Handle progress bar percents
			progressValue: NftUploadConst.MIN_PROGRESS_VALUE,
			maxProgressValue: NftUploadConst.MAX_PROGRESS_VALUE,

			unSaveNumber: 0,

			isDelivery: true,
			showPreviewContainer: false,
			showSaveButton: true,
			showButton: false,
			showSave: false,

			nftNotificationModalId: 'nft-notification-modal',
			nftModalContent: '',

			fileName: undefined,
			fileType: undefined,
			fileKey: '',
			imageNameInput: '',
			errorContents: '',
			uploadFileParams: {
				role: 'nft',
				nft_id: parseInt(this.$route.params.projectId),
			},

			buttonType: 'direct-screen-btn',
			showBottomButton: false,
			bottomButtonName: '納品完了画面にすすむ',
			showStatusBadge: false,
			badgeContent: '納品データ確認待ち',
			isShowUploadContainer: true,
			mailContent: '',
			directionLink: 'settlement',
			mailTitle: '',
			expiration: '60',
			subject: '',
			isSendCreator: false,
			deliveryStatus: '',

			NftDescription: NftUploadConst.NFT_DESCRIPTION,
			NFT_INFO: NftUploadConst.NFT_INFO,
			progressBarInterval: null,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
			'isNftUploading',
		]),
		disableSaveButton() {
			return !this.fileKey || !this.imageNameInput;
		},
		description() {
			const clientName = this.clientInfo?.client_name;
			const creatorName = this.creatorInfo?.register_name;
			const projectName = this.projectInfo?.project_name;
			// const estimateStartDate = this.formatDate(this.schedule?.estimate_start_date);
			// const estimateEndDate = this.formatDate(this.schedule?.estimate_end_date);
			const decisionStartDate = this.formatDate(this.schedule?.decision_start_date);
			const decisionEndDate = this.formatDate(this.schedule?.decision_end_date);
			// const creativeStartDate = this.formatDate(this.schedule?.creative_start_date);
			// const creativeEndDate = this.formatDate(this.schedule?.creative_end_date);
			// const proposalStartDate = this.formatDate(this.schedule?.proposal_start_date);
			// const proposalEndDate = this.formatDate(this.schedule?.proposal_end_date);
			const deliveryDate = this.formatDate(this.schedule?.delivery_date);
			//const description = `---\n\n## Produced with CoMoDe\n\n---\n\n## クライアント\n${clientName}\n\n---\n\n## クライアント\n${creatorName}\n\n---\n\n${projectName}\n\n---\n\n## CoMoDe( 共創 ) タイム\n\n- ${this.zoomDuration}\n\n- ・お見積作成工程 : ${estimateStartDate}~${estimateEndDate}\n- ・詳細ぎめ工程 : ${decisionStartDate}~${decisionEndDate}\n- ・クリエティブ工程 : ${creativeStartDate}~${creativeEndDate}\n- ・ご提案程 : ${proposalStartDate}~${proposalEndDate}\n- ・納品 : ${deliveryDate} `;
			const description = `---\n## Produced with CoMoDe\n---\n## クライアント: ${clientName}\n---\n## クリエイター: ${creatorName}\n---\n## 案件名: ${projectName}\n---\n## 納品: ${deliveryDate}`;
			return description;
		},

		zoomDuration() {
			if (this.projectInfo?.id) {
				this.rating =
					this.projectInfo['client_rating'] > 0 ? this.projectInfo['client_rating'] : 0;

				let hour =
					this.projectInfo['zoom_session_duration'] < 60
						? 0
						: Math.floor(this.projectInfo['zoom_session_duration'] / 60);
				let minute = Math.round(this.projectInfo['zoom_session_duration']) % 60;
				return hour + ' 時間 ' + minute + ' 分';
			}
		},

		disableUpload() {
			return this.progressValue !== 0;
		},

		// destinations() {
		// 	return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		// },
		// mailTemplate() {
		// 	return {
		// 		destinations: this.isSendCreator
		// 			? [this.creatorInfo['email_address']]
		// 			: [this.clientInfo['email_address']],
		// 		sender: this.managementMasterInfo['sender_email_address'],
		// 		subject: this.subject,
		// 		template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
		// 		template_params: {
		// 			projectName: this.projectInfo['project_name'],
		// 			projectId: this.projectId,
		// 			screenName: this.screenName,
		// 			clientName: this.clientInfo['client_name'],
		// 			clientId: this.clientInfo['id'],
		// 			emailContent: this.mailContent,
		// 			sendMailDate: moment().format('LLL'),
		// 			appLogo: this.appLogo,
		// 			appLogoFooter: this.appLogoPlain,
		// 			processingScreenUrl: this.isSendCreator
		// 				? `${this.projectInfo['process_creator_url']}delivery`
		// 				: `${this.projectInfo['process_client_url']}delivery`,
		// 			clientRegisterParams:
		// 				`register_name=${this.clientInfo['client_name']}&` +
		// 				`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
		// 				`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
		// 		},
		// 	};
		// },
		// lineMessage() {
		// 	return toLineMessageTemplate({
		// 		title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
		// 		message: this.mailContent,
		// 		currentUrl: this.isSendCreator
		// 			? `${this.projectInfo['process_creator_url']}delivery`
		// 			: `${this.projectInfo['process_client_url']}delivery`,
		// 	});
		// },
		// notifyContent() {
		// 	return this.projectInfo['notify_method'] == 0
		// 		? this.mailTemplate
		// 		: this.projectInfo['notify_method'] == 1
		// 			? this.lineMessage
		// 			: {
		// 				lineContent: this.lineMessage,
		// 				mailContent: this.mailTemplate,
		// 			}; // eslint-disable-line no-mixed-spaces-and-tabs
		// }
	},

	watch: {
		async userId(userId) {
			this.$store.commit('setIsAppProcessing', true);

			await this.getNFTRecord(userId, this.projectId);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Watch latest uploaded NFT
		 * @param {Array} newUploadedContents : New uploaded NFT items
		 */
		uploadedContents(newUploadedContents) {
			if (!newUploadedContents || newUploadedContents.length <= 0) {
				return;
			}
			// Because uploadedContents is reversed
			const lastNftIndex = 0;
			const lastNFT = newUploadedContents[lastNftIndex];
			this.$store.commit(
				'setIsNftUploading',
				!(lastNFT?.status === NftUploadConst.UPLOAD_STATUS.COMPLETED)
			);
		},
		// 'projectInfo.creator_finished': {
		// 	handler(newStatus) {
		// 		if (newStatus === 1) {
		// 			this.showBottomButton = false;
		// 		} else {
		// 			this.showBottomButton = true;
		// 		}
		// 	},
		// },
	},

	filters: {
		/**
		 * Filter file name
		 * @param {String} value image file key
		 * @returns file name
		 */
		toFileName: function (value) {
			if (!value) return '';
			return value.split('/').at(-1);
		},
	},
	methods: {
		async onRegistration() {
			if (this.uploadedContents.length >= NftUploadConst.MAX_ITEMS) {
				this.errorContents = NftUploadConst.LIMIT_ERROR_CONTENTS;
				this.$bvModal.show('error-modal');
				return;
			}

			if (!this.fileKey) {
				this.errorContents = NftUploadConst.NFT_ERROR_CONTENTS;
				this.$bvModal.show('error-modal');
				return;
			}

			let fileName =
				this.imageNameInput != ''
					? this.imageNameInput + '.' + this.fileType.split('/').at(-1)
					: this.fileName;
			let fileKey = this.fileKey;

			this.uploadedContents.unshift({
				created_at: this.formatDate(new Date()),
				file_name: fileName,
				opensea_url: '',
				status: NftUploadConst.UPLOAD_STATUS.WAITTING,
			});

			this.showPreviewContainer = true;
			this.handleProgressBar(200);
			this._revertImageData();

			const nft = await this.createNFT(fileName, fileKey);
			this.handleCreateNFT(nft, fileName);
		},
		async onDirectionButtonClick() {
			this.$store.commit('setIsAppProcessing', true);
			await this.updateProjectStatus(this.projectId, NFT_SCREEN_ID + 1);
			this.$emit('on-send-confirm-info', {
				screenId: NFT_SCREEN_ID,
				confirmInfo: true,
			});
			this.$store.commit('setIsAppProcessing', false);

			// Update client_finished of project and direct to finish screen
			await this._updateProject(this.projectId, {
				creator_finished: 1,
			});
			let projectInfo = { ...this.projectInfo, creator_finished: 1 };
			this.$store.commit('setProjectInfo', projectInfo);
			this.$router.push('finish');
			// this.$emit('on-open-settlement');
		},
		async _updateProject(projectId, content) {
			try {
				let response = await ProjectService.update(projectId, content);
				if (!response || response.status !== 200) {
					throw 'Upload Project failed!';
				}
				console.log('%c Upload Project successfully!', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},
		/**
		 * Handle create NFT error
		 * @param {Object} nft nft record
		 * @param {String} fileName
		 * @returns
		 */
		async handleCreateNFT(nft, fileName) {
			if (nft.message && nft.message.includes('NFT creation success')) {
				const nft_gdn_ids = nft?.nfts[0]?.nft_gdn_ids;
				// Create NFT record in DB
				let body = {
					contract_address: this.NFT_INFO.contract_address,
					description: this.description,
					file_name: fileName,
					token_id: null,
					project_id: this.projectId,
					user_id: this.userId,
					nft_gdn_id: nft_gdn_ids,
					status: NftUploadConst.UPLOAD_STATUS.WAITTING,
				};

				try {
					let nftRecord = await this.postNFTRecord(body);
					if (!nftRecord) {
						this.handleUploadErrorPopup();
						this.resetUploadData();
						return;
					}

					await this.getTokenIdInterval(nft_gdn_ids, nftRecord?.id);
				} catch (error) {
					this.handleUploadErrorPopup();
					this.resetUploadData();
					console.log(error);
				}
			} else {
				this.handleUploadErrorPopup();
				this.resetUploadData();
				console.log(nft?.error);
			}
		},

		handleUploadErrorPopup() {
			this.nftModalContent = NftUploadConst.NFT_ERROR_CONTENTS;
			this.$bvModal.show(this.nftNotificationModalId);
		},

		resetUploadData() {
			clearInterval(this.progressBarInterval);
			this.progressValue = 0;
			this.$store.commit('setIsNftUploading', false);
			this.uploadedContents.shift();
		},

		finishUploading() {
			this.progressValue = 100;
			setTimeout(() => {
				this.progressValue = 0;
			}, 200);
		},

		/**
		 *
		 * @param {Integer} interval timeout in milliseconds
		 */
		handleProgressBar(interval) {
			this.progressBarInterval = setInterval(() => {
				this.progressValue += 1;
				if (this.progressValue >= 90) {
					clearInterval(this.progressBarInterval);
				}
			}, interval);
		},

		/**
		 * Get token id using interval
		 * @param {String} nft_gdn_ids
		 * @param {Integer} nft_id
		 */
		async getTokenIdInterval(nft_gdn_ids, nft_id) {
			const tokenInterval = setInterval(async () => {
				const tokenIdRes = await this.getTokenId(nft_gdn_ids);

				if (tokenIdRes.error) {
					this.handleUploadErrorPopup();
					this.resetUploadData();
					console.log(tokenIdRes.error);
				} else if (tokenIdRes?.nfts[0]?.status === 'failed') {
					let body = {
						status: NftUploadConst.UPLOAD_STATUS.FAILED,
					};
					await this.updateNFTRecord(nft_id, body);
					this.handleUploadErrorPopup();
					this.resetUploadData();
					await this.getNFTRecord(this.userId, this.projectId);
				} else {
					const tockenId = tokenIdRes?.nfts[0]?.token_id;
					if (tockenId) {
						clearInterval(tokenInterval);

						let body = {
							token_id: parseInt(tockenId),
							status: NftUploadConst.UPLOAD_STATUS.COMPLETED,
						};
						await this.updateNFTRecord(nft_id, body);
						this.finishUploading();
						await this.getNFTRecord(this.userId, this.projectId);
					}
				}
			}, 4000);
		},

		onInputRemark(data) {
			this.imageNameInput = data.comment;
		},

		async onUploadImage(data) {
			this.fileName = data.name;
			this.fileType = data.type;
			this.fileKey = data.fileKey;
		},
		onMaxFileSizeError() {
			this.nftModalContent = NftUploadConst.MAX_FILE_SIZE_ERROR_CONTENTS;
			this.$bvModal.show(this.nftNotificationModalId);
		},
		/**
		 * Revert uploading component data to empty
		 */
		_revertImageData() {
			this.fileName = '';
			this.fileKey = '';
			this.imageNameInput = '';
			this.$refs.imageUpload._revertData();
			this.$refs.textArea._revertData();
		},

		onRemoveImageData() {
			this.fileName = '';
			this.fileKey = '';
		},

		formatDate(date) {
			return moment(date).format('YYYY/MM/DD');
		},

		filterNft(nfts) {
			let filteredNfts = [];

			if (nfts.length > 0) {
				nfts.map((nft) => {
					if (nft.status === NftUploadConst.UPLOAD_STATUS.FAILED) return;

					filteredNfts.unshift({
						id: nft.id,
						created_at: this.formatDate(nft?.date_created),
						file_name: nft?.file_name,
						opensea_url: `https://opensea.io/ja/assets/matic/${nft.contract_address}/${nft.token_id}`,
						status: nft.status,
						nft_gdn_id: nft.nft_gdn_id,
					});
				});
			}

			filteredNfts.sort(function (a, b) {
				return b.id - a.id;
			});

			return filteredNfts;
		},

		async createNFT(fileName, fileKey) {
			let imgURL = await this.getFileUrl(fileKey, CommonConst.ONE_WEEK_SECONDS);

			if (imgURL) {
				let nft = this.NFT_INFO;
				nft.metadata[0].title = fileName;
				nft.metadata[0].description = this.description;
				nft.metadata[0].file_fields[0].url = imgURL;
				let [createNFTResponse, errorCreateNFT] = await NFTGardenService.createNFT(nft);

				if (createNFTResponse) {
					return createNFTResponse;
				} else {
					return errorCreateNFT;
				}
			}
		},

		/**
		 * @param {String} nftGardenId
		 * @returns token_id of NFT Garden
		 */
		async getTokenId(nftGardenId) {
			let [response, error] = await NFTGardenService.getNftDetails([nftGardenId]);
			if (response) {
				return response;
			} else {
				console.log(error);
			}
		},

		/**
		 * Create record of NFT information
		 * @param {Object} body NFT record body
		 * @returns message
		 */
		async postNFTRecord(body) {
			try {
				let response = await NFTService.post({ doc_content: body });

				console.log('%c Create NFT record successfully!', 'color: green');
				return response.data;
			} catch (error) {
				console.log(error);
				return;
			}
		},

		async getNFTRecord(userId, projectId) {
			try {
				let response = await NFTService.getByUserAndProject(userId, projectId);

				console.log('%c Get NFT records successfully!', 'color: green');
				const nfts = this.filterNft(response.data);
				this.uploadedContents = nfts;
				setTimeout(() => {
					this.checkUnfinished();
				}, 0);
				if (nfts.length > 0) {
					this.showPreviewContainer = true;
				}
			} catch (error) {
				console.log(error);
				return;
			}
		},

		/**
		 * Create record of NFT information
		 * @param {Object} body NFT record body
		 * @returns message
		 */
		async updateNFTRecord(nftid, body) {
			try {
				let response = await NFTService.update(nftid, body);

				console.log('%c Update NFT record successfully!', 'color: green');
				return response.data;
			} catch (error) {
				console.log(error);
				return;
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async getFileUrl(key, expiration) {
			try {
				if (!key) {
					return '';
				}
				let response = await TransferService.get(key, expiration, true);

				console.log('%c Get image url successfully!', 'color: green');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
				return;
			}
		},

		async checkUnfinished() {
			if (this.uploadedContents.length <= 0) return;

			for (let element of this.uploadedContents) {
				if (element?.status === NftUploadConst.UPLOAD_STATUS.WAITTING) {
					this.handleProgressBar(150);
					await this.getTokenIdInterval(element.nft_gdn_id, element.id);
				}
			}
		},
	},

	async mounted() {
		moment.locale('ja');
		if (this.userId && this.projectId) {
			await this.getNFTRecord(this.userId, this.projectId);
		}
		if (this.projectInfo['creator_finished'] === 1) {
			this.showBottomButton = false;
		} else {
			this.showBottomButton = true;
		}
	},
};
